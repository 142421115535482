<template>
  <svg :height="side"
       :width="side"
       xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke-opacity="0.4" stroke-width="2">
      <circle v-for="(circle_binds, index) in cw_binds" :key="'cw' + index" v-bind="circle_binds"></circle>
      <circle v-for="(circle_binds, index) in ccw_binds" :key="'ccw'+ index" v-bind="circle_binds"></circle>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Circles1',
  components: {},
  directives: {},
  mixins: [],
  props: ['side'],
  data() {
    return {
      start: performance.now(),
      ccw: {
        modifier: 0,
        duration: 30 * 1000,
      },
      cw: {
        modifier: 0,
        duration: 40 * 1000
      }
    }
  },
  mounted() {
    requestAnimationFrame(this.animate)
  },
  computed: {
    ccw_binds_base() {
      return [
        this.getCircleBinds(0.39, "#8336b8", 0.5),
        this.getCircleBinds(0.35, "#644c94", 0.3),
        this.getCircleBinds(0.49, "#b7e053", 0.5),
      ]
    },
    ccw_binds() {
      const rotations = [-90, 0, -180];
      return this.ccw_binds_base.map((item, index) => {
        return {
          ...item,
          transform: `rotate (${rotations[index] + this.ccw.modifier} ${item.cx} ${item.cy})`
        }
      });
    },
    cw_binds_base() {
      return [
        this.getCircleBinds(0.37, "#b7e053", 0.5),
        this.getCircleBinds(0.34, "#644c94", 0.3),
        this.getCircleBinds(0.47, "#8336b8", 0.5),
      ]
    },
    cw_binds() {
      const rotations = [-90, 0, -180];
      return this.cw_binds_base.map((item, index) => {
        return {
          ...item,
          transform: `rotate (${rotations[index] - this.cw.modifier} ${item.cx} ${item.cy})`
        }
      });
    },
  },
  methods: {
    animate(time) {
      ['ccw', 'cw'].forEach(direction => {
        let time_fraction = (time - this.start) / this[direction].duration;
        if (time_fraction > 1) time_fraction = time_fraction % 1;

        this[direction].modifier = 360 * time_fraction;
      });

      requestAnimationFrame(this.animate);
    },
    getCircleBinds(r_coef, color, percent) {
      const radius = r_coef * this.side;
      const c = 2 * 3.14159 * radius;
      return {
        cx: this.side / 2,
        cy: this.side / 2,
        r: radius,
        stroke: color,
        'stroke-dasharray': `${c * percent} ${c * (1 - percent)}`
      }
    }
  }
}
</script>

<style scoped>
</style>
