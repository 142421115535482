<template>
  <div :class="[device_platform]"
       class="block-main-container">
    <div class="wrapper">
      <div class="text-container">
        <div class="text-img">
          <div class="text-black"></div>
          <div class="text-white"></div>
        </div>
        <div class="main-text">
          Автоматизация Управление Контроль<br>от агентства ПроКонтекст
          <!--a href="/сontextus.pdf"
             target="_blank">
            Автоматизация Управление Контроль<br>от агентства ПроКонтекст
          </a-->
        </div>
      </div>

      <div class="circles">
        <div class="purple-circle animated-purple"></div>
        <circles :side="circle_side" class="circle"></circles>
      </div>

      <div class="lines-container">
        <div class="lines"></div>
      </div>

      <div v-if="device_platform !== 'mobile'" class="input">
        <callback-input :class="{closetime: waiting, hidden: sent}"
                        :data="{form: 'main', type: 'sale'}"
                        :horizontal="device_platform !== 'mobile'"
                        v-on="$listeners">
          <span>Присоединиться</span>
        </callback-input>
        <div :class="{loader: waiting || sent, hidden: sent}"></div>
        <div :class="{success: sent}"
             class="cb-text-container">
          <div class="cb-text"><b>Вы успешно отправили заявку.</b><br>
            Наш специалист свяжется с вами и подробно разъяснит<br>
            дальнейшие совместные действия.
          </div>
        </div>
      </div>
    </div>

    <div v-if="device_platform === 'mobile'" class="input">
      <callback-input :class="{closetime: waiting, hidden: sent}"
                      :data="{form: 'main', type: 'sale'}"
                      :horizontal="device_platform !== 'mobile'"
                      v-on="$listeners">
        <span>Присоединиться</span>
      </callback-input>
      <div :class="{loader: waiting || sent, hidden: sent}"></div>
      <div :class="{success: sent}"
           class="cb-text-container">
        <div class="cb-text">
          <b>Вы успешно отправили заявку.</b><br>
          Наш специалист свяжется с вами<br> и подробно разъяснит<br>
          дальнейшие совместные действия.
        </div>
      </div>
      <div class="privacy">
        <div>
          Политика конфиденциальности: <br>
          <a
              class="privacy__item"
              href="/Privacy_Policy.pdf"
              target="_blank"
          >
            Общая
          </a>,
          <a
              class="privacy__item"
              href="/Privacy_Policy_of_the_contextus_analytics.pdf"
              target="_blank"
          >
            Google.Anaytics
          </a>,
          <a
              class="privacy__item"
              href="/Privacy_Policy_of_the_goo_auto_Google_Cloud_Platform_app.pdf"
              target="_blank"
          >
            Google.Ads
          </a><!--,-->
          <!--a
              class="privacy__item"
              href="/Privacy_Policy_of_the_pc_auto_Facebook_app.pdf"
              target="_blank"
          >
            Facebook
          </a-->
        </div>
      </div>

      <!--a class="presentation"
         href="/сontextus.pdf"
         target="_blank">
        <div>Презентация Contextus</div>
      </a-->

    </div>

  </div>
</template>


<script>
import Mixin from '../common/mixin';
import CallbackInput from "./callback-form/callback-input";
import Circles from "./circles";
import $ from 'jquery';

export default {
  name: "block-main",
  components: {Circles, CallbackInput},
  mixins: [Mixin],
  props: ['sent', 'waiting'],
  data() {
    return {
      circle_side: 980,
    }
  },
  mounted() {

    let handleResize = () => {
      const w = document.body.clientWidth;
      const values = [
        {
          max_w: 800,
          side: w * 1.04
        },
        {
          max_w: 1200,
          side: 650
        },
        {
          max_w: 1600,
          side: 750
        },
        {
          max_w: null,
          side: 980
        },
      ];

      let value = values.find(item => {
        return !item.max_w || (w < item.max_w)
      });
      this.circle_side = value.side;
    };
    $(window).on('resize', handleResize);
    handleResize();
  },
  computed: {
    is_ie() {
      if (document.documentMode || /Edge/.test(navigator.userAgent)) {
        return true;
      }
    }
  },
  methods: {
    getAgreement() {
      this.$emit('getAgreement');
    }
  }
}
</script>


<style lang="scss" scoped>
@import "../styles/constants.scss";

.hidden {
  display: none;
}

.tablet {
  &.block-main-container {
    max-width: 800px;
    height: 100vh;
  }

  .wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 800px;
    height: 450px;
  }

  .text-container {
    width: 1900px;
    height: 157px;

    & .main-text {
      font-size: 16px;
      margin-left: calc(50% - 137px);
    }

    & .text-white {
      background: url("../images/background/contextus-white-tails-tablet.svg") no-repeat;
      background-size: contain;
    }
  }

  .text-img {
    height: 110px;
  }

  .input {
    width: 100%;
  }

  .lines-container {
    width: 99.5%;
  }

  .circles {
    width: 650px;
    height: 650px;
    left: 0px;
  }
}

.mobile {
  &.block-main-container {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 96vw;
    margin-top: 4vw;
    top: unset;
    transform: unset;
  }

  .text-container {
    height: 25%;
    width: 121%;
    left: 50%;
    transform: translate(-53%, -52%);

    & .main-text {
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 20px;
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      -webkit-animation-name: text-after-load; /* Safari 4.0 - 8.0 */
      -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
      animation-delay: 1.5s;
      animation-name: text-after-load;
      animation-duration: 0.5s;
      animation-timing-function: linear;

      div {
        max-width: 275px;
      }
    }

    .text-img {
      height: 100%;

      & .text-black {
        background: url("../images/background/contextus-black-mobile.svg") no-repeat;
        background-size: contain;
      }

      & .text-white {
        background: url("../images/background/contextus-white-mobile-3.svg") no-repeat;
        background-size: contain;
      }
    }
  }

  .privacy, .presentation {
    font-size: 14px;
    color: #ccc;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
  }

  .privacy__item {
    text-decoration: underline;
  }

  .presentation {
    text-decoration: underline;
  }

  .input {
    top: 35px;
    opacity: 0;
    margin-bottom: 20px;
    justify-content: flex-start;
    -webkit-animation-name: text-after-load; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: text-after-load;
    animation-delay: 2s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    width: 87%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cb-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cb-text {
    max-width: 266px;
    letter-spacing: 1px;
    line-height: 23px;
    font-size: 11px;

    b {
      font-size: 13px;
      font-family: FuturaPTWebHeavy, sans-serif;
    }
  }

  .lines-container {
    height: 75%;
    width: 75%;
    right: -7%;
    top: 50%;
    transform: translateY(-51%);
  }

  .lines {
    background: url("../images/background/lines-mobile.svg") no-repeat right;
    background-size: contain;
  }

  .loader {
    right: 50%;
  }

  .circles {
    width: 104vw;
    height: 104vw;
    left: -2%;
  }

  @keyframes text-after-load {
    from {
      opacity: 0;
      margin-top: 10px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }

  @-webkit-keyframes text-after-load {
    from {
      opacity: 0;
      margin-top: 10px;
    }
    to {
      opacity: 1;
      margin-top: 0;
    }
  }
}

.block-main-container {
  background-color: var(--default-violet);
  position: relative;
  width: 100%;
  max-width: 1600px;
  height: 100vh;
}

.wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  height: 900px;
}

/* TEXT positioning */

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 110%;
  height: 330px;

  & .main-text {
    opacity: 0;
    font-size: 20px;
    letter-spacing: 0.5px;
    margin-top: 15px;
    margin-left: 590px;
    -webkit-animation-name: text-after-load; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.5s; /* Safari 4.0 - 8.0 */
    animation-name: text-after-load;
    animation-delay: 1.5s;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
}

.text-img {
  width: 100%;
  height: 254px;

  display: flex;
  justify-content: center;

  & .text-black, .text-white {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .text-black {
    background: url("../images/background/contextus-black-tails.svg") no-repeat;
    background-size: contain;
    -webkit-animation-name: text-pulsate; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
    animation-name: text-pulsate;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }

  & .text-white {
    background: url("../images/background/contextus-white-tails.svg") no-repeat;
    background-size: contain;
  }
}

.input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: auto;
  top: 80%;
  display: flex;
  justify-content: flex-end;
}

.cb-text-container {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.cb-text {
  line-height: 25px;
  opacity: 0.7;
  font-size: 16px;

  b {
    font-size: 18px;
  }
}

/* LINES */

.lines-container {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.lines {
  width: 100%;
  height: 100%;
  background: url("../images/background/line.svg") no-repeat right;
  background-size: contain;
}

/* CIRCLES positioning */

.webp {
  & .purple-circle {
    background: url("../images/background/purple-circle.webp") center no-repeat;
    background-size: contain;
  }
}

.no-webp {
  & .purple-circle {
    background: url("../images/background/purple-circle.png") center no-repeat;
    background-size: contain;
  }
}

.circles {
  width: 980px;
  height: 980px;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1;

  & .purple-circle, .circle {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  & .animated-purple {
    -webkit-animation-name: circle-pulsate; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 8s; /* Safari 4.0 - 8.0 */
    animation-name: circle-pulsate;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;
  }
}

.loader {
  position: absolute;
  top: 50%;
  right: 20%;
  transform: translate(50%, -50%);
  background: url("../images/background/loader.svg") no-repeat right;
  width: 30px;
  height: 30px;
  background-size: contain;
  animation: closetime 2s forwards reverse;
}

/* CallBack send classes */

.closetime {
  -webkit-animation-name: closetime; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 2s; /* Safari 4.0 - 8.0 */
  animation-name: closetime;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes closetime {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.success {
  &.cb-text-container {
    -webkit-animation-name: success; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 1s; /* Safari 4.0 - 8.0 */
    animation-name: success;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
}

@keyframes success {
  from {
    opacity: 0;
    margin-top: 40px;
  }
  to {
    margin-top: 0;
    opacity: 1;
  }
}

/* TEXT animation */

@keyframes text-pulsate {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes text-pulsate {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes text-after-load {
  from {
    opacity: 0;
    margin-top: 80px;
  }
  to {
    opacity: 1;
    margin-top: 40px;
  }
}

@-webkit-keyframes text-after-load {
  from {
    opacity: 0;
    margin-top: 80px;
  }
  to {
    opacity: 1;
    margin-top: 40px;
  }
}

/* CIRCLES animation */

/* for purple */
@keyframes circle-pulsate {
  from {
    transform: scale(0.7, 0.7);
    opacity: 0.4;
  }
  to {
    transform: scale(1, 1);
    opacity: 0.8;
  }
}

@media screen and (min-width: 1600px) {
  .text-container {
    height: 334px;
    width: 4050px;

    & .main-text {
      margin-left: calc(50% - 290px);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .block-main-container {
    max-width: 1200px;
  }

  .wrapper {
    height: 675px;
  }

  .text-container {
    height: 237px;
    width: 2900px;

    & .main-text {
      font-size: 18px;
      /*letter-spacing: 2px;*/
      margin-left: calc(50% - 209px);
    }
  }

  .text-img {
    height: 170px;
  }

  .input {
    width: 87%;
  }

  .lines-container {
    width: 99%;
  }

  .circles {
    width: 750px;
    height: 750px;
    left: 60px;
  }
}

@media screen and (min-width: 600px) {
  .mobile {
    .text-container {
      transform: translate(-53%, -51.5%);

      .text-img {
        & .text-white {
          background: url("../images/background/contextus-white-mobile-2.svg") no-repeat;
          background-size: contain;
        }
      }
    }

    .lines-container {
      right: -7%;
    }
  }
}
</style>
