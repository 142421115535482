<template>
  <form :class="[device_platform, {'horizontal': horizontal}, data ? data.form : '']"
        :data-formname="form_name"
        class="callback-form"
        @submit.prevent="submitThis">
    <div :class="[{centered: data && data.form === 'credit'},{left: leftAlign}]"
         class="input-block">
      <input ref="name"
             v-model="name"
             :title="'Введите Ваше Имя'"
             name=”name”
             placeholder="Ваше Имя"
             required
             type="text">
      <input ref="phone"
             v-model="phone"
             v-mask
             :pattern="'.*[0-9]{1}.*[0-9]{3}.*[0-9]{3}.*[0-9]{4}'"
             :title="'Введите номер телефона'"
             name=”tel”
             placeholder="Номер телефона"
             required
             type="tel">
      <input ref="email"
             v-model="email"
             :title="'Введите адрес электронной почты'"
             name=”email”
             placeholder="Email"
             required
             type="email">
      <button ref="submitter"
              :class="[{disabled: disabled, enabled: !disabled}]"
              :disabled="disabled"
              class="purple-btn CKFormTrigger"
              type="submit">
        <slot></slot>
      </button>
    </div>
  </form>
</template>

<script>
import Mixin from '@/common/mixin';
import Mask from '@/common/mask';

export default {
  name: "callback-input",
  directives: {Mask},
  mixins: [Mixin],
  props: ['horizontal', 'data', 'leftAlign'],
  data() {
    return {
      phone: '',
      name: '',
      email: '',
      some_random_value: '',
    }
  },
  computed: {
    form_name() {
      return this.data ? this.data.form : 'default'
    },
    valid_email() {
      return this.validEmail(this.email);
    },
    valid_name() {
      return this.validName(this.name);
    },
    disabled() {
      return !(this.valid_email && this.valid_name && this.phone)
    }
  },
  methods: {
    validEmail: function (email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validName(name) {
      const re = /\S/;
      return re.test(name);
    },
    submitThis(event) {
      if (this.$refs.phone.validity.valueMissing || !this.$refs.phone.validity.valid) {
        this.$refs.phone.setCustomValidity('Введите номер телефона');
      }
      if (!this.$refs.phone.validity.valueMissing && !this.$refs.phone.validity.patternMismatch) {
        this.$refs.phone.setCustomValidity('');
      }
      if (this.$el.reportValidity()) {
        this.$emit('callBack', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          data: this.data,
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/constants.scss";

.tablet {
  .purple-btn, input {
    width: 140px;
    height: 30px;
    font-size: 11px;
    padding: 7px;
  }
}

.mobile {
  .callback-form {
    width: 100%;
    max-width: 325px;
    margin: auto;
  }

  .purple-btn {
    width: 100%;
    margin: 5px;
  }

  input {
    width: 100%;
    margin: 5px;
  }
}

a {
  cursor: pointer;
}

input {
  height: 40px;
  width: 165px;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: text;
  background: rgba(0, 0, 0, 0.32);
  outline: none;
  padding: 0 20px;
  color: var(--light-violet);
  font-family: FuturaPTWebHeavy, sans-serif;

  &::placeholder {
    overflow: visible;
    color: var(--light-violet);
    font-family: FuturaPTWebHeavy, sans-serif;
  }

  &:hover:not(.disabled) {
    opacity: 1;
  }

  &:active:not(.disabled) {
    opacity: 1;
  }
}

.purple-btn {
  width: 100%;
}

.disabled {
  opacity: 0.8;
  cursor: default;
}

.input-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.horizontal {
  .input-block {
    flex-direction: row;
  }

  input {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.purple-btn {
  height: 40px;
  width: 165px;
  font-size: 13px;
}

.enabled {
  animation: enable 0.5s forwards;
  -o-animation: enable 0.5s forwards;
  -ms-animation: enable 0.5s forwards;
  -webkit-animation: enable 0.5s forwards;
  -moz-animation: enable 0.5s forwards;
}

@keyframes enable {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    background-color: purple;
  }
}

@-o-keyframes enable {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    background-color: purple;
  }
}

@-ms-keyframes enable {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    background-color: purple;
  }
}

@-webkit-keyframes enable {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    background-color: purple;
  }
}

@-moz-keyframes enable {
  from {
    opacity: 0.8;
  }
  to {
    opacity: 1;
    background-color: purple;
  }
}
</style>
