<template>
  <div class="block-presentation">
    <div v-for="(img, index) in images" :key="index" class="block-presentation__slide">
      <img :data-src="img" class="block-presentation__slide-img lazyload">
    </div>


  </div>
</template>

<script>
import 'lazysizes';

export default {
  name: 'block-presentation',
  data() {
    let images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(index => {
      return require(`../images/presentation/${index}.jpg`)
    })
    return {
      images: images
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/constants.scss";

.block-presentation {
  padding: 16px;
  background-color: #fff;

  &__slide-img {
    max-width: 100%;
  }
}

</style>