<template>
  <div>
    <div id="app"
         style="visibility: hidden">
      <block-main :sent=sent
                  :waiting=waiting
                  v-on="block_handlers"></block-main>
      <!--block-presentation></block-presentation-->
      <!-- <a href="/Privacy_Policy.pdf" class="privacy" target="_blank">
        <div>Политика конфиденциальности</div>
      </a> -->
      <div class="privacy">
        <div>
          Политика конфиденциальности:
          <a
              class="privacy__item"
              href="/Privacy_Policy.pdf"
              target="_blank"
          >
            Общая
          </a>,
          <a
              class="privacy__item"
              href="/Privacy_Policy_of_the_contextus_analytics.pdf"
              target="_blank"
          >
            Google.Anaytics
          </a>,
          <a
              class="privacy__item"
              href="/Privacy_Policy_of_the_goo_auto_Google_Cloud_Platform_app.pdf"
              target="_blank"
          >
            Google.Ads
          </a><!--,-->
          <!--a
              class="privacy__item"
              href="/Privacy_Policy_of_the_pc_auto_Facebook_app.pdf"
              target="_blank"
          >
            Facebook
          </a-->
        </div>
      </div>
      <!--a class="presentation"
         href="/сontextus.pdf"
         target="_blank">
        <div>Презентация Contextus</div>
      </a-->
    </div>
  </div>
</template>

<script>
import BlockMain from './components/block-main';
import BlockPresentation from './components/block-presentation.vue';
import Preloader from './components/preloader';
import Mixin from './common/mixin';

import axios from 'axios';
import $ from 'jquery';
import smoothScrollTo from "./common/smoothScrollTo";

export default {
  name: 'app',
  components: {
    BlockMain,
    BlockPresentation,
    Preloader
  },
  mixins: [Mixin],
  data: function () {
    return {
      show: {
        agreement: false,
        load: false,
        get: false,
        thanks: false,
        coupon: false,
      },
      sent: false,
      waiting: false,
      loaded: false,
      data: {},
    };
  },
  created() {
    document.getElementsByTagName('body')[0].onload = function () {
      document.getElementById('app').setAttribute('style', 'visibility: visible');
      document.getElementById('app').classList.add('fade');
    }
  },
  mounted() {
    $('html').on('keyup', (event) => {
      if (event.keyCode === 27) {
        this.closeThings();
      }
    });
    history.scrollRestoration = 'manual';
    let hash = window.location.hash.replace('#', '');
    if (hash) {
      this.scrollTo(hash);
    }


    // setTimeout(() => {this.sent = true},  7000);

  },
  methods: {
    closeThings() {
      this.show.get = false;
      this.show.thanks = false;
      this.show.agreement = false;
      this.show.coupon = false;
      this.data = {};
      this.unblockScroll();
    },
    closeAgreement() {
      this.show.agreement = false;
      if (!this.show.get) {
        this.unblockScroll();
      }
    },
    getAgreement() {
      this.show.agreement = true;
      this.blockScroll();
    },
    handleGetCoupon(data) {
      this.show.coupon = true;
      this.data = data;
      this.blockScroll();
    },
    handleGetCall(data) {
      this.show.get = true;
      this.data = data;
      this.blockScroll();
    },
    scrollTo(where) {
      let target = document.getElementById(where);

      smoothScrollTo($('#' + where).offset().top - (this.device_platform === 'desktop' ? 120 : 150));
    },
    callThere(event) {
      let tocall = event.phone.match(/\d+/g).join(''),
          source = event.data || this.data,
          path = source.form
              + (source.model ? ',' + source.model : '')
              + (source.comp ? ',' + source.comp : ''),
          type = source.type,
          referrer = document.referrer,
          ga = window.ga && window.ga.getAll && window.ga.getAll()[0] && window.ga.getAll()[0].get('clientId'),
          totype = [
            {
              id: 1,
              key: 'sale'
            },
            {
              id: 2,
              key: 'credit'
            },
            {
              id: 3,
              key: 'service'
            }]
              .find((item) => {
                return item.key === type;
              }),
          tosend = {
            type: totype && totype.id || 1,
            name: event.name,
            phone: tocall,
            email: event.email,
            url: window.location.href,
            path: path,
            debug: isDebug(tocall),
            //call_row: event.ck_url,
            referrer: document.referrer,
            ga_client_id: ga
          };
      if (tosend.phone.toString().length > 10) {
        // this.closeThings();
        this.waiting = true;
        let ret = axios.post('./back/send/', {...tosend});
        if (!ret) {
          this.sent = true
        } else {
          ret.then(() => {
            this.sent = true;
            this.waiting = false
          })
        }

        // return axios.post('./back/send/', {...tosend}).then(res => console.log(res));
      }

      function isDebug(phone) {
        const debugs = ["71111111111", "79999999999"];
        if (debugs.some((dphone) => dphone === phone)) {
          return 1;
        }
        return 0;
      }
    },
  },
  computed: {
    block_handlers() {
      return {
        'getAgreement': this.getAgreement,
        'scrollTo': this.scrollTo,
        'callBack': this.callThere,
        'getCall': this.handleGetCall,
        'getCoupon': this.handleGetCoupon
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

.privacy, .presentation {
  position: absolute;
  bottom: 0;
  font-size: 14px;
  color: #ccc;
  padding: 0 0 10px;
  display: flex;
  flex-direction: row;
}

.privacy {
  left: 10px;
}

.privacy__item {
  text-decoration: underline;
}

.presentation {
  right: 10px;
  text-decoration: underline;
}

@media screen and (max-width: 800px) {
  .privacy, .presentation {
    display: none;
  }
}

.interblock-space_tiny {
  height: 5px;
}

.interblock-space_small {
  height: 60px;
}

.interblock-space_medium {
  height: 135px;
}

.fade {
  animation: fade 2s forwards linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
